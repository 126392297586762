iframe#webpack-dev-server-client-overlay{display:none!important}
$mobile: 767px;
$main_color: #fff;
$dark_color: #000;
$background-color: #3C486B;
$light_color: #f3f6fc;
$lightgray_color: #d9d9d9;
$text_color: #505050;
$border-radius: 10px;

@mixin flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 999;
}

.header {
  @include flex_center;
  flex-direction: column;
  background-color: $background-color;
  height: auto;
  padding: 0.7em 0.5em;
  .sub_header {
    width: 100%;
    display: flex;
    height: auto;
    justify-content: left;
    .header_button {
      display: inline-flex;
      width: 100%;
      justify-content: flex-end;
      color: #fff;
      align-items: center;
      div svg {
        font-size: 2.5rem;
        cursor: pointer;
        z-index: 999;
      }
      div {
        display: flex;
        align-items: center;
      }
    }
    .header_datepicker {
      display: inline-flex;
      width: 10%;
      z-index: 999;
      align-items: center;
      input {
        height: 34px;
      }
    }
  }

  h1 {
    color: $main_color;
    font-weight: bold;
    font-size: 2rem;
  }
  
  .tabs {
    display: flex;
    width: 100%!important;
    list-style: none;
    padding: .5em 0 0 0;
    justify-content: left;
    
    button {
      cursor: pointer;
      background-color: $lightgray_color;
      border: $main_color;
      padding: 10px;
      font-size: 1rem;
      font-weight: bold;
      color: $dark_color;
      width: 300px;
      border: 1.5px solid $dark_color;
      
      &.active {
        background-color: $main_color;
        color: $dark_color;
        z-index: 1000;
      }
      &.apply {
        z-index: 1000;
        width: 300px;
      }
      
      &.disabled {
        z-index: 1000;
      }
    }
  }
}

.main_content {
  display: flex;
  justify-content: space-between;
  // background-color: $light_color;
  flex-direction: column;
  height: 90vh;
  background-color: #f3f6fc;

  .section {
    flex-basis: 45%;
    background-color: #f3f6fc;
    // padding: 0.5em 0;
    // border-bottom: 1.5px solid #ddd;

      h2 {
        color: $text_color;
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      ul {
        margin: 0;
        padding: 0;
        width: 100%;
      }
      li { 
        display: flex;
        width: 100%;
        height: 100px;
        list-style-type: none;
        justify-content: space-around;
        align-items: center;
        white-space: nowrap;
        position: relative;
      }
      input {
        display: block;
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #dbdbdb;
        border-radius: 5px;
        background-color: #fff;
        font-size: 14px;
        color: #333;
        box-sizing: border-box;
        // padding-block: 1px;
        // padding-inline: 2px;
        font-family: none;
      }
  }
  .section_right {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .section_row {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      padding: 0.5em 0;
      .section_item {
        display: inline-flex;
        width: 50%;
        flex-direction: column;
        padding: 0.5em 0;
        align-items: center;
        text-align: center;
      }
      .section_content {
        width: 40%;
        justify-content: center;
        border-bottom: 1px solid $dark_color;
        text-align: right;
      }
    }
  }      
}

.footer {
  @include flex_center;
  padding: 1rem;
  background-color: #f3f6fc;
  p {
    color: $main_color;
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 4000px) {
  .tabs {
    position: absolute;
    top: 0;
  }
}

@media (max-width: $mobile) {
  .header {
    @include flex_center;
    flex-direction: column;
    background-color: $background-color;
    height: auto;
    padding: 0.5em;
    .sub_header {
      width: 100%;
      display: flex;
      height: auto;
      justify-content: left;
      .header_button {
        display: inline-flex;
        width: 100%;
        justify-content: flex-end;
        color: #fff;
        div {
          display: flex;
          align-items: center;
        }
      }
      .header_datepicker {
        display: inline-flex;
        width: 10%;
        z-index: 999;
        align-items: center;
        input {
          height: 34px;
          width: 120px;
        }
      }
    }
  
    h1 {
      color: $main_color;
      font-weight: bold;
      font-size: 2rem;
    }
    
    .tabs {
      display: flex;
      width: 100%;
      list-style: none;
      // padding: .5em 0 0 0;
      padding: 0;
      // justify-content: left;
      justify-content: space-between!important;
      // justify-content: left!important;

      
      button {
        cursor: pointer;
        background-color: $lightgray_color;
        border: $main_color;
        padding: 10px;
        font-size: 1rem;
        font-weight: bold;
        color: $dark_color;
        width: 50%;
        border: 1.5px solid $dark_color;
        
        &.active {
          background-color: $main_color;
          color: $dark_color;
          z-index: 1000;
        }

        &.apply {
          z-index: 1000;
          width: 30%!important;
        }

        &.disabled {
          z-index: 1000;
        }
      }
    }
  }
  .main_content {
    flex-direction: column;
    min-height: 85vh;
    .section {
      flex-basis: 100%;
    }
    .Work-input-component {
      margin: 0 4px;
    }
    .Work-input-label {
      font-size: 10px;
    }
    .edit_button-container {
      float: right;
      margin-right: -1px;
    }

    button.edit {
      display: block;
      width: 100%;
      border: none;
      border-radius: 3px;
      background-color: #00DFA2;
      font-weight: bold;
      color: white;
      cursor: pointer;
      margin-left: -10px;
      padding: 0.6rem 0.3rem;
      font-size: 14px;
      margin-top: 24px;
      float: right;
      &:hover {
        background-color: darken(#00DFA2, 5%);
      }
    }
    .section_right {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .section_row {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        padding: 0.5em 0;
        .section_item {
          display: inline-flex;
          width: 50%;
          flex-direction: column;
          padding: 0.5em 0;
          align-items: center;
          text-align: center;
        }
        .section_content {
          width: 80%;
          justify-content: center;
          border-bottom: 1px solid $dark_color;
          text-align: right;
        }
      }
    }
    
  }

  .footer {
    padding: 0.5rem;
    button {
      padding: 1em;
      margin: 0;
    }
    p {
      font-size: 0.9rem;
    }
  }
}


.editButton {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.deleteButton {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.holdButton {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.section_left {
  position: relative;
  left: 0;
  transition: left 0.3s ease-in-out;
}

.section_left.slide_in {
  left: 100%;
}

.main_line_div {
  border-bottom: 1px solid #dadada;
  padding: .5em 0;
  display: flex;
  align-items: center;
}
.main_div {
  padding: 1em;
}
.main_div:nth-child(1) {
  padding: 0 1em 1em 1em;
}

.line_title_div {
  width: 60%;
  display: inline-flex;
} 
.line_time_div {
  width: 75%;
  display: inline-flex;
  justify-content: right;
  border-bottom: none;
  input {
    border-bottom: none;
  }
  // div {
  //   width: 100%;
  // }
}
.list_menu_holiday {
  background-color: #FFF0F0;
}
.list_menu_saturday {
  background-color: #F0F0FF;
}
.css-153eyq5-MuiModal-root-MuiDialog-root .MuiDialogContent-root {
  padding: 5px 16px;
}

.DidRest_component {
  width: calc(25%  - 4px);
  margin: 0 0.4em 0 0;
  text-align: left;
  font-weight: bold;
  justify-content: space-around;
  button {
      width: 100%;
  }
}

.DidRest_component:nth-child(1) {
  width: calc(5%  - 4px);
  margin: 0 0 0 0.2em;
  div {
      width: 100%;
  }
}
.DidRest_component:nth-child(2) {
  width: calc(30%  - 4px);
  margin: 0 0 0 0.2em;
  div {
      width: 100%;
  }
}
.DidRest_component:nth-child(3) {
  width: calc(15%  - 4px);
  margin: 0 0 0 0.2em;
  div {
      width: 100%;
  }
}
.DidRest_component:nth-child(4) {
  width: calc(35% - 4px);
  margin: 0 0.2em 0 0.2em;
  div {
      width: 100%;
  }
}