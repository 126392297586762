$main_color: #fff;
$dark_color: #000;
$background_color: #3C486B;
$light_color: #f3f6fc;
$lightgray_color: #d9d9d9;
$text_color: #505050;
$border_radius: 10px;

@mixin flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
}

.header {
  @include flex_center;
  background-color: $background_color;
  height: auto;
  justify-content: left;
  height: 6vh;
  h1 {
    color: $main_color;
    font-weight: bold;
    font-size: 2rem;
  }
  
  .tabs {
    display: flex;
    width: 90%;
    list-style: none;
    padding: 0.1em;
    justify-content: center;
    
    button {
      cursor: pointer;
      background-color: $lightgray_color;
      border: #fff;
      padding: 10px;
      font-size: 1rem;
      font-weight: bold;
      color: #000;
      width: 50%;
      border: 1.5px solid #000;
      
      &.active {
        background-color: $main_color;
        color: $dark_color;
      }
    }
  }
}

.main_content {
  display: flex;
  justify-content: space-between;
  background-color: $light_color;
  flex-direction: column;

  .section {
    flex-basis: 45%;
    padding: 0;
    border-radius: $border_radius;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      h2 {
        color: $text_color;
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      ul {
        margin: 0;
        padding: 0;
        width: 100%;
      }
      li {
        display: flex;
        width: 100%;
        height: 100px;
        list-style-type: none;
        justify-content: space-around;
        align-items: center;
      }
      input {
        display: block;
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #dbdbdb;
        border-radius: 5px;
        background-color: #fff;
        font-size: 14px;
        color: #333;
        box-sizing: border-box;
      }
    }
    .section_right {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .section_row {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        padding: 0.5em 0;
        .section_item {
          display: inline-flex;
          width: 50%;
          flex-direction: column;
          padding: 0.5em 0;
          align-items: center;
          text-align: center;
        }
        .section_content {
          width: 40%;
          justify-content: center;
          border-bottom: 1px solid #000;
          text-align: right;
        }
      }
    }      
}
.header_title {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  color: #fff;
}
.btn {
  cursor: pointer;
}
table {
  white-space: nowrap;
}
.adminFieldArea {
  padding: 1em;
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
}

.main_line_div:nth-child(1):nth-child(2):nth-child(3) {
  border-bottom: 1px solid #dadada;
  width: 15%;
}
.main_line_div:nth-child(4) {
  width: 15%;
}
.main_div {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 94vh;
}
.main_line_div button{
  width: 100%;
  margin: 1em 0;
}
.main_line_div button{
  width: 100%;
  margin: 1em 0;
}
.line_title_div, .line_time_div {
  width: 50%;
  display: inline-flex;
}
// classNameを変更する際にハイフン(-)がある場合は、中括弧でまとめてアクセスする必要があります。 
// (例: styles['header-datepicker'])また、複数のクラスを使用する場合は空白を区切り文字として使用して作成します(例:styles.section + ''+steles['section-left'])。