.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    background-color: transparent!important;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0)!important;
    width: 100%;
    overflow-x: auto;
  }
  .css-13xy2my {
    background-color: unset!important;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none!important;
    width: 100%;
    overflow-x: auto;
}
td label.css-1ald77x{
  z-index: 0 !important;
}