iframe#webpack-dev-server-client-overlay{display:none!important}
$mobile: 767px;

.rstra-action-button {
    background: scroll;
    display: flex;
    align-items: center;
}
.rstra-dots-button {
    position: absolute;
    right: 0;
    bottom: 0;
}
.list_menu_item {
    display: block;
}
.list_menu_item_blue {
    background-color: #F0F0FF!important;
    span {
        color: #0000FF;
    }
    div {
        background-color: #F0F0FF!important;
    }
}
.list_menu_item_red {
    background-color: #FFF0F0!important;
    span {
        color: #FF0000;
    }
    div {
        background-color: #FFF0F0!important;
    }
}
.list_menu_item_green {
    background-color: #f0fff0!important;
    span {
        color: #108558;
    }
    div {
        background-color: #f0fff0!important;
    }
}
// .list_menu_item {
//     background-color:#fff;
// }
.rstra-content-container {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: left;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease;
    /* background-color: #FFF; */
    box-sizing: border-box;
    padding-top: 1rem;
    padding-bottom: 0.8rem;
    z-index: 2;
}

.css-1qzmggg-MuiTypography-root-MuiDialogTitle-root {
    text-align: center;
}

.line_title_div, .line_time_div {
    width: 50%;
    display: inline-flex;
    justify-content: center;
    margin: 1em 0;
    button{
        width: 75%;
    }
    button.active {
        background-color: #d32f2f;
        color: #fff;
    }
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 350px!important;
    width: 100%!important;
}

.submitBtnArea {
    display: flex;
    justify-content: center;
    padding: 1em;
    button {
        width: 50%;
    }
}


button.ChangePassBtn {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #000000;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    margin: .7em 0; 
    &:hover {
      background-color: darken(#000000, 5%);
    }
}

div.myChangeTime {
    display: flex;
    flex-direction: column;
    width: 23.2vw;
}

/* PC , table width (width 768px ~ 1023px)*/ 
@media all and (min-width:768px) and (max-width:1599px) {
    div.myChangeTime {
        display: flex;
        flex-direction: column;
        width: 35vw;
    } 
} 
  
  

@media (max-width: $mobile) {
    div.myChangeTime {  
        display: flex;
        flex-direction: column;
        width: 80vw;
    }
}