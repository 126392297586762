iframe#webpack-dev-server-client-overlay{display:none!important}
.rstra-action-button {
    background: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rstra-dots-button {
    position: absolute;
    right: -5px!important;
    bottom: 0;
}
.list_menu_item_blue {
    background-color: #F0F0FF!important;
    span {
        color: #0000FF;
    }
    div {
        background-color: #F0F0FF!important;
    }
}
.list_menu_item_red {
    background-color: #FFF0F0!important;
    span {
        color: #FF0000;
    }
    div {
        background-color: #FFF0F0!important;
    }
}
.list_menu_item_holiday {
    background-color: #FFF0F0!important;
    span {
        color: #FF0000;
    }
    div {
        background-color: #FFF0F0!important;
    }
}
// .list_menu_item_green {
//     background-color: #f0fff0!important;
//     span {
//         color: #108558;
//     }
//     div {
//         background-color: #f0fff0!important;
//     }
// }
// .list_menu_item_notice {
//     background-color: #b5d1ff!important;
//     span {
//         color: #5795fa;
//     }
//     div {
//         background-color: #b5d1ff!important;
//     }
// }
// .list_menu_item_expire {
//     background-color: #d9d9df!important;
//     span {
//         color: #9f9faa;
//     }
//     div {
//         background-color: #d9d9df!important;
//     }
// }
// .list_menu_item_etc {
//     background-color: #b7ffe3!important;
//     span {
//         color: #108558;
//     }
//     div {
//         background-color: #b7ffe3!important;
//     }
// }
.list_menu_item {
    background-color: #f3f6fc;
}
.rstra-content-container {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: left;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease;
    background-color: #f3f6fc;
    box-sizing: border-box;
    padding-top: 1rem;
    padding-bottom: 0.8rem;
    z-index: 2;
}
.rstra-actions-container {
    background-color: #f3f6fc;
}

.css-1qzmggg-MuiTypography-root-MuiDialogTitle-root {
    text-align: center;
}

.line_title_div, .line_time_div {
    width: 50%;
    display: inline-flex;
    justify-content: center;
    margin: 1em 0;
    button{
        width: 75%;
    }
    button.active {
        background-color: #d32f2f;
        color: #fff;
    }
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    max-width: 350px!important;
    width: 100%!important;
}

.submitBtnArea {
    display: flex;
    justify-content: center;
    padding: 1em;
    button {
        width: 50%;
    }
}

.WorkInput_component {
    width: calc(27%  - 4px);
    margin: 0 0.1em;
}
.WorkInput_label {
    font-size: 14px;
}
.dashboard_main_content__ODEeX .dashboard_section__fCdSY input {
    padding: 0.75rem 0.25rem;   
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 5px 8px!important;
}
.css-1hof3tc {
    padding: 5px 8px!important;
}
.css-uhb5lp {
    max-width: 350px!important;
    width: 100%!important;
}

.DidRest_component {
    width: calc(30%  - 4px);
    margin: 0 0.4em 0 0;
    text-align: center;
    font-weight: bold;
    button {
        width: 100%;
    }
}
.DidRest_component:nth-child(3) {
    width: 100%;
    margin: 0 0 0 0.2em;
    div {
        width: 100%;
    }
}

.css-1ocm6s1 {
    background-color: #3C486B;
    color: #fff;    
}

// WorkFormModal 
div.timepicker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 4px 0 5px!important;
    text-align: center!important;
}
div.timepicker .css-1uvydh2 {
    padding: 4px 0 5px!important;
    text-align: center!important;
}
div.timepicker .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none!important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42)!important;
    border-radius: 0!important;
}
div.timepicker .css-igs3ac {
    border: none!important;
    border-bottom: none!important;
    border-radius: 0!important;
}
div.timepicker .css-1laqsz7-MuiInputAdornment-root {
    justify-content: right!important;
    width: 1%!important;
}
div.timepicker .css-1nvf7g0 {
    justify-content: right!important;
    width: 1%!important;
}
div.timepicker .css-1x5jdmq {
    padding: 5px!important;
    text-align: center!important;
}

.css-1ed1o9w-MuiList-root-MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
    display: none!important;
}
.css-5ikgf7::-webkit-scrollbar {
    display: none!important;
}
// DidWork 
div.timepick .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 22px 14px!important;
    text-align: left !important;
    font-size: 14px;
}
div.timepick .css-1uvydh2 {
    padding: 22px 14px!important;
    text-align: left !important;
    font-size: 14px;
}
div.timepick .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none!important;
    border-radius: 0!important;
}
div.timepick .css-igs3ac {
    border: none!important;
    border-radius: 0!important;
}
div.timepick .css-1laqsz7-MuiInputAdornment-root {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 5%;
}
div.timepick .css-1nvf7g0 {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 5%;
}
div.timepick .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 0;
}
div.timepick .css-1bn53lx {
    padding: 0;
}
div.timepick .css-1x5jdmq {
    padding: 1.5em 5px!important;
}
.css-uhb5lp {
    max-height: calc(100% - 50px)!important;
}

.css-ece9u5 {
    width: 100%;
}

// .css-slyssw {
//     margin-right: -12px;
// }
// .css-1bn53lx {
//     padding-right: 8px!important;
// }


.fc .fc-view-harness {
    height: 1500px!important;
}

.fc .fc-multimonth {
    overflow: unset!important;
}

.fc-media-screen .fc-multimonth-singlecol .fc-multimonth-header {
    position: relative!important;
}

.fc-h-event {
    background-color: red;
    border: 1px solid red;
    display: block;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin: 1em!important;
}

.fc-day-sat {
    background-color: #F2F7FF!important;
}
.fc-day-sun {
    background-color: #ffcccc!important;
}
.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #4B4B4B!important;
}

.holiday_etc {
    background-color: #108558!important;
    border: 1px solid #108558!important;
}
.holiday_expire {
    background-color: #9f9faa!important;
    border: 1px solid #9f9faa!important;
}
.holiday_notice {
    background-color: #5795fa!important;
    border: 1px solid #5795fa!important;
}

.css-slyssw {
    // background-color: #FFF!important;
}