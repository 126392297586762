$primary-color: #000000;
$border-radius: 5px;


// 1. Pink: #FFC0CB 
// 2. Turquoise Blue: #40E0D0 
// 3. Lilac: #C8A2C8 
// 4. Coral Pink: #FF7F50 
// 5. Mint Green: #98FB98 
// 6. Mustard Yellow: #FFDB58 
// 7. Sky Blue: #87CEEB 
// 8. Purple: #800080 
// 9. Sunset Orange: #FD5E53 
// 10. Aqua Marine: #7FFFD4 

.join-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh;
  margin-top: 5vh;
  // background-color: #f8f8f8;
  
  .join-form {
    width: 100%;
    max-width: 300px;
    padding: 2rem;

    h1 {
      text-align: center;
      margin-bottom: 1.5rem;
      color: $primary-color;
    }

    // input {
    //   display: block;
    //   width: 100%;
    //   margin-bottom: 1rem;
    //   padding: 0.75rem 1rem;
    //   border: 1px solid #dbdbdb;
    //   border-radius: $border-radius;
    //   background-color: #f6f6f6;
    //   font-size: 14px;
    //   color: #333;
    //   box-sizing: border-box;

    //   &:focus {
    //     outline: 1px solid $primary-color;
    //   }
    // }

    div.text-center {
      text-align: center;
    }

    button.joinBtn {
      display: block;
      width: 100%;
      padding: 0.75rem 1rem;
      border: none;
      border-radius: $border-radius;
      background-color: $primary-color;
      font-size: 16px;
      font-weight: bold;
      color: white;
      cursor: pointer;
      margin: .7em 0; 
      &:hover {
        background-color: darken($primary-color, 5%);
      }
    }
  }
}

@media (max-width: 767px) {
  .join-form {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}


.w-50 {
  width: 50%;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.loading-container img {
  max-width: 100%;
  width: 100px;
}